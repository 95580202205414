<template>
  <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
    <v-row>
      <v-col>
        <v-form ref="orgForm" v-model="valid">
          <v-card flat outlined :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode">
            <v-card-title class="headline">
              {{ $store.getters.getTextMap().create_new_organisation }}
            </v-card-title>

            <v-row class="px-10">

              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-col cols="12"  v-if="!loading">
                <v-text-field v-model="form.name" :loading="loading" rounded dense outlined :counter="25"
                  :rules="nameRules" :label="$store.getters.getTextMap().organisation_name" required></v-text-field>
              </v-col>


              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-col cols="12"  v-if="!loading">
                <v-text-field v-model="form.address" rounded dense outlined :loading="loading" :counter="56"
                  :rules="addressRules" :label="$store.getters.getTextMap().address" required></v-text-field>
              </v-col>


              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-col cols="12" v-if="!loading">
                <v-select v-if="!loading" v-model="countryCode" :items="countryCodes" item-text="name" rounded dense
                  outlined item-value="code" :label="$store.getters.getTextMap().country" :rules="selectRules" required
                  clearable></v-select>
              </v-col>




              <v-col cols="12" v-if="!loading">
                <v-select v-if="!loading" v-model="form.state" :items="states" item-text="state" rounded dense outlined
                  item-value="code" :label="$store.getters.getTextMap().state" :rules="selectRules" required
                  clearable></v-select>
              </v-col>

            </v-row>

            <v-row class="px-10">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-col cols="12" align="center" v-if="!loading">
                <v-select v-if="!loading" v-model="form.org_type" :items="$store.state.orgTypes" item-text="org_type"
                  rounded dense outlined item-value="id" :label="$store.getters.getTextMap().organisation_type"
                  :rules="selectRules" required clearable></v-select>
              </v-col>
            </v-row>



            <v-card-actions>
              <v-row class="pb-4">
                <v-col align="center">
                  <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                  <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit"
                    class="mx-2">
                    {{ $store.getters.getTextMap().submit }}
                  </v-btn>

                  <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor"
                    :dark="$store.getters.getColorPalette().isDark" @click="onReset" class="mx-2">
                    {{ $store.getters.getTextMap().clear }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'

export default {
  name: 'CreateOrganisation',
  components: {
    InfoAlert
  },
  data() {
    return {
      form: {
        name: null,
        org_type: null,
        address: null
      },
      loading: false,
      info: null,
      showDismissibleAlert: false,
      valid: false,
      nameRules: [
        v => !!v || this.$store.getters.getTextMap().name_is_required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
        v => (v && v.length <= 25 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_25_characters,
      ],
      addressRules: [
        v => !!v || this.$store.getters.getTextMap().required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().required,
        v => (v && v.length <= 56 && v.length > 0) || this.$store.getters.getTextMap().too_long,
      ],
      selectRules: [
        v => (v != null) || this.$store.getters.getTextMap().required
      ],
      countryCode: null,
      states: null
    }
  },

  computed: {
    countryCodes() {
      let op = []
      if (this.$store.state.countryCodes) {
        this.$store.state.countryCodes.forEach((item) => {
          
          op.push(
            {
              name: item.name,
              code: item.code
            }
          )
        })

      }

      return op

    }
  },

  watch: {
    countryCode: {
      handler() {
        this.getStates()

      },
      deep: true
    }
  },
  methods: {

    getStates() {


      if (this.countryCode) {
        let payload = {
          country_code: this.countryCode
        }
        axios.post(this.$store.state.api + '/getStates', payload, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(response => {
          if (response.data.status == "success") {
    
            let data = response.data.data

             this.states = []

            data.forEach(item => {
              this.states.push({
                state: item.state,
                code: item.code
              })
            })

          }
        })

      }


    },

    onSubmit() {
      //evt.preventDefault();
      this.$refs.orgForm.validate()
      if (this.valid) {
        this.loading = true;

        this.form['country_code'] = this.countryCode

        console.log(this.form);

        axios
          .post(this.$store.state.api + "createOrganisation", this.form, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
          .then(response => {

            console.log(response);
            if (response.data.status == "success") {

              this.$store.commit("setUser", response.data.user);
              this.$store.commit("setJWT", response.data.jwt);
              localStorage.setItem('enture_token', response.data.jwt)
              this.$store.commit("setLoggedIn", true)
              //console.log(response.data.jwt);
              this.$store.dispatch("refresh");

              setTimeout(() => { this.$store.dispatch("refresh"); this.$router.push("/dashboard"); }, 5000);
              //console.log("redirecting to dashboard")
              this.info = 'Organisation Created'
              this.showDismissibleAlert = true;
              this.onReset()
              //this.$router.push("/dashboard");
              this.$emit('close')
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            //this.onReset()
            this.loading = false;
          })
          .catch(error => {
            this.onReset()
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
          });
      }
    },
    onReset() {
      this.$refs.orgForm.reset()



    }
  }
}
</script>
<style scoped>
.v-sheet.v-card {
  background-color: #B2DFDB;
}
</style>